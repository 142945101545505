import { createContext, useContext } from "react";

type DictContent = Record<string, unknown>;

type DictContextType = {
  dict: DictContent;
  debug: boolean;
};

export const DictContext = createContext<DictContextType>({ dict: {}, debug: false });

export const useDict = () => {
  const { dict, debug } = useContext(DictContext);

  return {
    d: genD(dict, debug),
  };
};

function genD(dict: DictContent, debug: boolean) {
  return (key: string) => d(key, dict, debug);
}

function d(key: string, dict: DictContent, debug: boolean): string {
  const master = findValue(key, dict["master"] as DictContextType);
  const custom = findValue(key, dict["custom"] as DictContextType);

  if (debug) {
    return `${custom}[${master}]`;
  }

  return custom || master || key;
}

function findValue(key: string, dict: DictContent) {
  let result: any = dict;

  if (key.split(":").length > 2) {
    return "multiple ns key is not supported" + key;
  }

  const [namespace, nestedKey] = key.split(":");
  const keys = [namespace, ...nestedKey.split(".")];

  // 各キーを辞書内で再帰的に検索
  for (const k of keys) {
    // 現在のキーが辞書内に存在するかどうかを確認
    if (result && typeof result === "object" && k in result) {
      result = result[k];
    } else {
      // キーが見つからない場合はnullを返す
      return null;
    }
  }
  return result;
}

export function fetchDict(context: any, tenant_id: string, namespaces: string[]) {
  return fetch(`${process.env.NEXT_PUBLIC_FRONTEND_API_BASE_URL}/api/dict?tenant_id=${tenant_id}&namespaces=${namespaces}`, {
    headers: { cookie: context.req.headers.cookie },
  }).then((res) => res.json());
}
